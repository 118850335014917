import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { getAssetUrl } from '../../services/cdn'
import styled from 'styled-components'

const Cover = styled.div`
    background-size: cover, cover;
    background-image: linear-gradient(to bottom, rgba(0,0,0, ${props => props.opacity}), rgba(0,0,0, ${props => props.opacity})), url('${props => props.mobileSrc ? props.mobileSrc : props.src}');
    background-position: ${props => props.x}% ${props => props.fullMobile ? 50: props.y}%;
    background-repeat: no-repeat;
    height: ${props => props.fullMobile || props.height === null ? `100%`: props.height + `px`};
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;

    .display-1 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
    }

    @media screen and (min-width: 768px) {
        background-position: ${props => props.x}% ${props => props.y}%;
        background-image: linear-gradient(to bottom, rgba(0,0,0, ${props => props.opacity}), rgba(0,0,0, ${props => props.opacity})), url('${props => props.src}');
        height: ${props => props.height !== null ? props.height + `px` : `100%`};

        .display-1 {
            font-size: 6rem;
        }
    }
`

const Hero = ({ src, mobileSrc, fullMobile = false, size = 12, children, autoAlign = true, height = 768, x = 50, y = 50,  dim = 0, className = '', style, ...otherProps  }) => {
    return (
        <Cover opacity={dim} src={getAssetUrl(src)} mobileSrc={getAssetUrl(mobileSrc)} x={x} y={y} height={height} className={className} fullMobile={fullMobile} >
            {
                autoAlign ?
                (
                    <Container className="mt-5 pt-5">
                        <Row>
                            <Col lg={size} className="text-lg-left align-self-center text-right">
                                { children }
                            </Col>
                        </Row>
                    </Container>
                )
                    :
                (children)
            }
        </Cover>
    )
}

export default Hero
