import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col, UncontrolledCarousel } from 'reactstrap'
import { getDocumentUrl } from '../../services/cdn';

const DownloadLink = ({ fileName, link, header = null, isNewWindow = true }) => {
    if (header !== null) {
        return <li className="list-unstyled mt-3 text-center"><h3>{ header }</h3></li>
    }
    else {
        return <li className="border-0 list-group-item"><a className="d-flex justify-content-between align-items-center" style={{ color: "#333", textDecoration: "none"}} href={`${getDocumentUrl(link)}`} target={isNewWindow ? '_blank' : false}>{fileName}<br /><i className="fa fa-sign-out text-muted fa-lg"></i></a></li>
    }
}

const Product = ({ noMargin = false, id, partnerId, title, partnershipTitle, partnershipItems, carouselItems, downloads, inquireLink, departmentTitle, department1, department2, department3, children }) => {
    let downloadLinks = downloads.map((item, key) => {
        return (
            <DownloadLink key={`${key}-${item.fileName}`}fileName={item.fileName} link={item.link} {...item} />
        )
    })
    return (
        <div id={id}>
            <Container className={`${noMargin ? 'my-0' : 'my-5'}`} id={partnerId}>
                <Row className="my-2">
                    <Col md="12">
                        <h1 className={`${noMargin ? 'pt-0' : 'pt-5'}`}>{ title }</h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <div className="mb-3">
                            <p className="mb-0">{ partnershipTitle }</p>
                            <ul className="list-group list-group-flush">
                                {
                                    partnershipItems.map((item, key) => {
                                        return (
                                            <li key={`${key}-${item.name}`} className="list-group-item"><i className={`${item.iconClass} text-primary mr-2`} />{ item.name }</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="mb-3">
                            <UncontrolledCarousel items={carouselItems} indicators={false} control={true} />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md="8">
                        <div className="text-justify">
                            { children }
                        </div>
                    </Col>
                    <Col md="4">
                        <h3 className="text-center">Documents</h3>
                        <ul className="list-group">
                            { downloadLinks }
                        </ul>
                        <Row>
                            <Col md="12">
                                <Link className="btn btn-primary" to={inquireLink}>Send Inquire request</Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* <Row className="py-3">
                    <Col md="12">
                        <h4><b>{ departmentTitle }</b></h4>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" sm="4">
                        <Link to={`${department1.link}`}><img alt="DEPARTMENT" className="img-fluid d-block" src={`${getAssetUrl(department1.src)}`} /></Link>
                    </Col>
                    <Col md="4" sm="4">
                        <Link to={`${department2.link}`}><img alt="DEPARTMENT" className="img-fluid d-block" src={`${getAssetUrl(department2.src)}`} /></Link>
                    </Col>
                    <Col md="4" sm="4">
                        <Link to={`${department3.link}`}><img alt="DEPARTMENT" className="img-fluid d-block" src={`${getAssetUrl(department3.src)}`} /></Link>
                    </Col>
                </Row> */}
            </Container>
        </div>
    )
}

export default Product