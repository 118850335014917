import React from 'react'
import { Link } from "gatsby"
import { Location, navigate } from '@reach/router'
import { Container, Collapse, Navbar as BootstrapNavbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap'
import AnchorLink from 'react-anchor-link-smooth-scroll'

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          collapsed: true
        }
      }
    
    toggleNavbar = () => {
        this.setState((state, props) => {
            return { collapsed: !state.collapsed }
        });
    }

    goHome = (e) => {
        e.preventDefault()
        navigate('/')
    }

    render() {
        return (
            <Location>
                {
                    ({ location, navigate }) => (
                        <BootstrapNavbar fixed="top" dark expand="lg" color="primary" style={{ minHeight: '64px'}}>
                            <Container>
                                <NavbarBrand href="#" onClick={this.goHome} className="mr-auto">AGRICULTURE</NavbarBrand>
                                <NavbarToggler onClick={this.toggleNavbar} />
                                <Collapse isOpen={!this.state.collapsed} navbar className="text-center justify-content-end">
                                    <Nav navbar>
                                        <NavItem className="mx-2">
                                            <Link className="nav-link" to="/">Home</Link>
                                        </NavItem>
                                        <NavItem className="mx-2">
                                            <Link className="nav-link" to="/offerings">Offerings</Link>
                                        </NavItem>
                                        <NavItem className="mx-2">
                                            <Link className="nav-link" to="/partnering">Partnering</Link>
                                        </NavItem>
                                        <NavItem className="mx-2">
                                            <Link className="nav-link" to="/about">About</Link>
                                        </NavItem>
                                        <NavItem className="mx-2">
                                            <Link className="nav-link" to="/inquire">Inquire</Link>
                                        </NavItem>
                                        {/* <NavItem className="mx-2">
                                            <Link className="nav-link" to="/downloads">Downloads</Link>
                                        </NavItem> */}
                                        <NavItem className="mx-2">
                                            <AnchorLink className="nav-link" href="#contact">Contact</AnchorLink>
                                        </NavItem>
										
										<NavItem className="mx-2">
											<a className="nav-link" href="https://sidc.coop/data-privacy/">Privacy Notice</a>
										</NavItem>

                                    </Nav>
                                </Collapse>
                            </Container>
                        </BootstrapNavbar>
                    )
                }
            </Location>
        )
    }
}

export default Navbar