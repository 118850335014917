import React from 'react'
import PropTypes from 'prop-types'
import './vendors.js'
import DataPrivacyModal from './Sections/DataPrivacyModal'

class Root extends React.Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    showModal() {
        this.setState({show: true});
    }

    hideModal() {
        this.setState({show: false});
    }
    render() {
        return (
            <div id="root">
                { this.props.children }
                <DataPrivacyModal/>
            </div>
        )
    }
}

Root.propTypes = {
    children: PropTypes.node.isRequired,
  }

export default Root