import React from 'react'
import { getAssetUrl } from '../../services/cdn';

const Benefit = ({ title, children, iconClass, src = null }) => {
    return (
        <div className="block benefit"> 
            { src === null ? 
                <i className={`d-block ${iconClass} mb-2 text-primary`}></i> 
                :
                <img src={getAssetUrl(src)} alt={title} height="70px" />
            }
            <h4> <b>{ title }</b> </h4>
            <p>{ children }</p>
        </div>
    )
}

export default Benefit