import React, {useState} from 'react'
import {Modal,  ModalBody,ModalFooter,Button} from 'reactstrap';
import { getAssetUrl } from '../../services/cdn'


const DataPrivacyModal = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
        <div >
        <Button style={{position:"fixed", bottom:"30px", right:"100px"}} onClick={toggle}>Data Privacy</Button>
        <img style={{position:"fixed", bottom:"30px", right:"250px", width:"60px"}} className="img-fluid d-block pt-5" alt="DPO" src={getAssetUrl('app/dpo.png')}  />
        <Modal size="xl" isOpen={modal}  toggle={toggle}>

            <ModalBody>
            <h5>Data Privacy</h5>
            <br></br>
            <p>By using the messaging features of our third party(FACEBOOK), you enable them to process your personal data. SIDC has no control or will not be liable for any loss, misuse of personal information.</p>
            <br></br>
            <p>For more details of the Facebook Data Privacy Policy kindly click this: <a href="https://www.facebook.com/privacy/explanation/">DATA POLICY</a>, <a href="https://www.facebook.com/policies/cookies/">COOKIES POLICY</a> and <a href="https://www.facebook.com/terms/">FACEBOOK TERMS</a> .</p>
            </ModalBody>
            <ModalFooter>
            <Button color="success" onClick={toggle}>OK{" "}</Button>
            </ModalFooter>
        </Modal>
        </div>
    );


}
export default DataPrivacyModal
