import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import { getAssetUrl } from '../../services/cdn'

const OfferItem = ({ name, iconClass, link = null, header = null }) => {
    if (header !== null) {
        return (
            <li className="border-0 list-unstyled text-center mt-3"><h3>{ header }</h3></li>
        )
    }
    else {
        return (
            <li className="border-0 list-group-item d-flex justify-content-between align-items-center">
                { link !== null ? <a className="text-body" rel="noopener noreferrer" target="_blank" href={link}>{ name }</a> : name }
                <br />
                <i className={`${iconClass} text-muted fa-lg`}></i>
            </li>
        )
    }
}

const VideoProduct = ({ noMargin = false, id, partnerId, title, advantagesTitle, advantages, videoLinks, offerTitle, offers, photoLinks, departmentTitle, department1, department2, department3, children }) => {
    let offerItems = offers.map((item, key) => {
        return (
            <OfferItem key={`${key}-${item.name}`} name={item.name} iconClass={item.iconClass} {...item}  />
        )
    })

    const photoColumnWidth = 3
    return (
        <div id={id}
            style={{
                backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(164, 213, 166))',
                backgroundPosition: 'top left',	
                backgroundSize: '100%',	
                backgroundRepeat: 'repeat'
            }}
        >
            <Container className={`${noMargin ? 'my-0' : 'mt-5'}`} id={partnerId}>
                <Row className="my-2">
                    <Col md="12">
                        <h1 className={`${noMargin ? 'pt-0' : 'pt-5'}`}>{ title }</h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <div className="mb-3">
                            <p className="lead text-left">{ advantagesTitle }</p>
                            <ul className="list-group">
                                {
                                    advantages.map((item, key) => {
                                        return (
                                            <li key={`${key}-${item.name}`} className="list-group-item"><i className={`${item.iconClass} text-primary mr-2`} />{ item.name }</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </Col>
                    <Col lg="6">
                        {
                            typeof videoLinks !== 'undefined' &&
                            <div className="mb-3">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <video className="embed-responsive-item" controls="controls"> 
                                        {
                                            videoLinks.map((video, key) => {
                                                return (
                                                    <source key={key} src={getAssetUrl(video.link)} type={video.type} />           
                                                )
                                            })
                                        }
                                        Your browser does not support HTML5 video. 
                                    </video>
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md="12" lg="8" className="mb-5">
                        <div className="text-justify">
                            { children }
                        </div>
                    </Col>
                    <Col md="12" lg="4" className="mb-5">
                        <h3 className="text-center">{ offerTitle }</h3>
                        <ul className="list-group">
                            { offerItems }
                        </ul>
                    </Col>
                </Row>
                <Row className="mb-2">
                    {
                        photoLinks.map((link, key) => (
                            <Col md={{ size: photoColumnWidth}} key={key}>
                                <img alt="PRODUCT" className="img-fluid d-block m-auto pb-3" src={getAssetUrl(link)} />
                            </Col>
                        ))
                    }
                </Row>
                <Row className="py-3">
                    <Col md="12">
                        <div className="text-center text-sm-left">
                            <h4><b>{ departmentTitle }</b></h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" sm="4">
                        { typeof department1.link !== 'undefined' ? 
                            (<Link to={department1.link}><img alt="DEPARTMENT" className="img-fluid d-block m-auto" src={getAssetUrl(department1.src)} /></Link>) :
                            (<img alt="DEPARTMENT" className="img-fluid d-block m-auto" src={getAssetUrl(department1.src)} />)
                        }
                    </Col>
                    <Col md="4" sm="4">
                        { typeof department2.link !== 'undefined' ? 
                            (<Link to={department2.link}><img alt="DEPARTMENT" className="img-fluid d-block m-auto" src={getAssetUrl(department2.src)} /></Link>) :
                            (<img alt="DEPARTMENT" className="img-fluid d-block m-auto" src={getAssetUrl(department2.src)} />)
                        }
                    </Col>
                    <Col md="4" sm="4">
                        { typeof department3.link !== 'undefined' ? 
                            (<Link to={department3.link}><img alt="DEPARTMENT" className="img-fluid d-block m-auto" src={getAssetUrl(department3.src)} /></Link>) :
                            (<img alt="DEPARTMENT" className="img-fluid d-block m-auto" src={getAssetUrl(department3.src)} />)
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default VideoProduct