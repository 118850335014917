import Hero from './Hero'
import HeroCarousel from './HeroCarousel'
import UncontrolledHeroCarousel from './UncontrolledHeroCarousel'
import DownloadCenter from './DownloadCenter'
import CoreValues from './CoreValues'
import DataPrivacyModal from './DataPrivacyModal'
export {
    Hero,
    HeroCarousel,
    UncontrolledHeroCarousel,
    DownloadCenter,
    DataPrivacyModal,
    CoreValues
}